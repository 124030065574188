<template>
    <router-link :to="to" class="menu-card">
        <p class="title">{{ title }}</p>
        <i class="icon" :class="icon" v-if="icon"></i>
        <div class="svg-icon" v-if="svg_icon" v-html="svg_icon"></div>
    </router-link>
</template>
  
<script>
export default {
    name: "MenuCard",
    props: {
        title: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ''
        },
        svg_icon: {
            type: String,
            default: ''
        },
        to: {
            type: [String,Object],
            default: '/'
        },
    }

}
</script>
  
<style scoped lang="scss"></style>
  